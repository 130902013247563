import Core from 'foundation-sites';
import Keyboard from 'foundation-sites';

// import Box from 'foundation-sites';
// import onImagesLoaded from 'foundation-sites';
import MediaQuery from 'foundation-sites';
import Motion from 'foundation-sites';
// import Move from 'foundation-sites';
// import Nest from 'foundation-sites';
// import Timer from 'foundation-sites';
// import Touch from 'foundation-sites';
import Triggers from 'foundation-sites';
// import Abide from 'foundation-sites';
import Accordion from 'foundation-sites';
// import AccordionMenu from 'foundation-sites';
// import Drilldown from 'foundation-sites';
import Dropdown from 'foundation-sites';
import DropdownMenu from 'foundation-sites';
// import Equalizer from 'foundation-sites';
// import Interchange from 'foundation-sites';
// import Magellan from 'foundation-sites';
// import OffCanvas from 'foundation-sites';
import Orbit from 'foundation-sites';
import ResponsiveMenu from 'foundation-sites';
import ResponsiveToggle from 'foundation-sites';
// import Reveal from 'foundation-sites';
// import Slider from 'foundation-sites';
import SmoothScroll from 'foundation-sites';
import Sticky from 'foundation-sites';
// import Tabs from 'foundation-sites';
// import Toggler from 'foundation-sites';
// import Tooltip from 'foundation-sites';
// import ResponsiveAccordionTabs from 'foundation-sites';

// import Swiper bundle with all modules installed
//import Swiper from 'swiper/bundle';

Foundation.addToJquery($);

import './common';

const rows = [
	'opdrachten'
];

rows.forEach(name => {
	if( document.querySelector('section.' + name) ) {
		import('./rows/' + name).then(module => module.default).then(row => row());
	}
});

$(document).foundation();

window.$ = $;